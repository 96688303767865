import { WindowLocation } from "@reach/router"
import React from "react"
import Layout from "../components/layout/layout"
import SearchLayout from "../components/maps/searchLayoutBase"

export default function Search({
  location,
  pageContext,
}: {
  location: WindowLocation
  pageContext: any
}) {
  return (
    <Layout title="Zoeken" location={location} pageContext={pageContext}>
      <SearchLayout pageContext={pageContext} />
    </Layout>
  )
}
